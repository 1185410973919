<template>
  <div class="search-box-group">
    <form novalidate>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          name="search"
          placeholder="Search products"
        />
        <button type="submit" class="btn btn-search">
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="10.0142"
              cy="9.06076"
              rx="8.48293"
              ry="8.31076"
              stroke="#112A63"
              stroke-opacity="0.5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.9141 15.2725L22.0311 21.2499"
              stroke="#112A63"
              stroke-opacity="0.5"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {};
</script>
