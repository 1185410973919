<template>
  <div class="main-wrapper min-vh-100 position-relative">
    <HeadlessNav :page="'pay with MoMo agent'" />
    <section class="tw-main">
      <div class="section-top-stack">
        <div class="tw-main-detials">
          <div
            class="form-group top-group-img d-flex justify-content-between align-items-centet mb-4"
          >
            <img src="/assets/img/momo-payment-2.png" alt="" />
            <div class="payment-details">
              <h6 class="font-12">NGN 5,000</h6>
              <h6 class="font-12">joymichelle@gmail.com</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row-block-display">
        <h6 class="text-tile text-center font-12 mb-5">
          We are confirming your payment. This could take a couple of minutes
        </h6>
        <div class="pending-confirmation d-none">
          <img src="/assets/img/89.png" width="100" height="100" alt="" />
        </div>
        <div class="payment-confirmation-block mb-4">
          <div class="payment-block payment-success mb-4">
            <span class="text">Payment Made</span>
            <span class="loader-done">
              <img src="/assets/img/group-5.svg" alt="" />
            </span>
          </div>
          <div class="payment-block payment-pending mb-4">
            <span class="text">Payment Confirmed</span>
            <span class="loader-done">
              <img src="/assets/img/89.gif" width="25" height="26" alt="" />
            </span>
          </div>
        </div>
        <div class="text-tile-link">
          <a href="#" class="text-center font-12 mb-5">
            Need help with the transactions?
          </a>
        </div>
        <div class="payment-lined-text">
          <img src="/assets/img/Lock.svg" alt="" />
          <span>SECURED BY MOMO AGENT</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeadlessNav from "../../../components/dashboard/HeaderlessNav.vue";

export default {
  name: "payment-method",
  components: { HeadlessNav },
};
</script>

<style>
.payment-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.pending-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-block-display {
  border-top: 1px solid rgba(0, 4, 45, 0.1);
  padding-top: 16px;
}
.row-block-display .text-tile {
  font-weight: 300;
  width: 75%;
  margin: 0 auto;
  font-size: 12px;
}
.payment-block {
  border: 1px solid rgba(6, 20, 53, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  margin-bottom: 14px;
}

.text-tile-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-tile-link a {
  text-decoration: underline;
}
.payment-lined-text {
  border: 1px solid rgba(6, 20, 53, 0.2);
  border-radius: 4px;
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 70%;
}
.payment-lined-text span {
  color: #846100;
  font-size: 10px;
  font-weight: bolder;
}
</style>
