<template>
  <!-- navbar -->
  <nav class="navbar-fixed position-fixed">
    <div class="navbar-wrapper">
      <ul class="nav-items">
        <li
          :class="`nav-item ${currentRoute === 'dashboard' ? 'active' : null}`"
        >
          <router-link to="/dashboard" class="nav-link">
            <svg
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.03223 6.99902C3.03223 4.37381 3.06034 3.49902 6.53223 3.49902C10.0041 3.49902 10.0322 4.37381 10.0322 6.99902C10.0322 9.62424 10.0433 10.499 6.53223 10.499C3.02115 10.499 3.03223 9.62424 3.03223 6.99902Z"
                stroke="#112A63"
                stroke-opacity="0.5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0322 6.99902C14.0322 4.37381 14.0603 3.49902 17.5322 3.49902C21.0041 3.49902 21.0322 4.37381 21.0322 6.99902C21.0322 9.62424 21.0433 10.499 17.5322 10.499C14.0212 10.499 14.0322 9.62424 14.0322 6.99902Z"
                stroke="#112A63"
                stroke-opacity="0.5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.03223 17.999C3.03223 15.3738 3.06034 14.499 6.53223 14.499C10.0041 14.499 10.0322 15.3738 10.0322 17.999C10.0322 20.6242 10.0433 21.499 6.53223 21.499C3.02115 21.499 3.03223 20.6242 3.03223 17.999Z"
                stroke="#112A63"
                stroke-opacity="0.5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0322 17.999C14.0322 15.3738 14.0603 14.499 17.5322 14.499C21.0041 14.499 21.0322 15.3738 21.0322 17.999C21.0322 20.6242 21.0433 21.499 17.5322 21.499C14.0212 21.499 14.0322 20.6242 14.0322 17.999Z"
                stroke="#112A63"
                stroke-opacity="0.5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="nav-link-text">{{ $t("dashboard") }}</span>
          </router-link>
        </li>
        <li :class="`nav-item ${currentRoute === 'store' ? 'active' : null}`">
          <router-link to="/store" class="nav-link">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Iconly/Light/Home">
                <g id="Home">
                  <path
                    id="Home_2"
                    d="M9.42284 21.5219V18.4552C9.42282 17.6751 10.0587 17.0413 10.8466 17.0361H13.7327C14.5244 17.0361 15.1661 17.6714 15.1661 18.4552V18.4552V21.5314C15.1659 22.1937 15.6999 22.735 16.3687 22.7505H18.2927C20.2107 22.7505 21.7656 21.2112 21.7656 19.3123V19.3123V10.5883C21.7554 9.84132 21.4011 9.13984 20.8036 8.68352L14.2233 3.43579C13.0706 2.52205 11.4318 2.52205 10.279 3.43579L3.72765 8.69304C3.12788 9.14751 2.77301 9.85015 2.76562 10.5978V19.3123C2.76562 21.2112 4.3205 22.7505 6.23854 22.7505H8.16259C8.84798 22.7505 9.4036 22.2004 9.4036 21.5219V21.5219"
                    stroke="#112A63"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </g>
            </svg>
            <span class="nav-link-text">{{ $t("store") }}</span>
          </router-link>
        </li>
        <li :class="`nav-item ${currentRoute === 'orders' ? 'active' : null}`">
          <router-link to="/order" class="nav-link">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Iconly/Light/Buy">
                <g id="Buy">
                  <path
                    id="Stroke 1"
                    d="M3.51562 4.00024L5.59562 4.36024L6.55862 15.8332C6.63562 16.7702 7.41862 17.4892 8.35862 17.4862H19.2676C20.1646 17.4882 20.9256 16.8282 21.0526 15.9402L22.0016 9.38224C22.1076 8.64924 21.5986 7.96924 20.8666 7.86324C20.8026 7.85424 5.92963 7.84924 5.92963 7.84924"
                    stroke="#112A63"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Stroke 3"
                    d="M14.8906 11.5452H17.6636"
                    stroke="#112A63"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Stroke 5"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.91998 20.9529C8.22098 20.9529 8.46398 21.1969 8.46398 21.4969C8.46398 21.7979 8.22098 22.0419 7.91998 22.0419C7.61898 22.0419 7.37598 21.7979 7.37598 21.4969C7.37598 21.1969 7.61898 20.9529 7.91998 20.9529Z"
                    fill="#112A63"
                    fill-opacity="0.5"
                    stroke="#112A63"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Stroke 7"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.2003 20.9529C19.5013 20.9529 19.7452 21.1969 19.7452 21.4969C19.7452 21.7979 19.5013 22.0419 19.2003 22.0419C18.8993 22.0419 18.6562 21.7979 18.6562 21.4969C18.6562 21.1969 18.8993 20.9529 19.2003 20.9529Z"
                    fill="#112A63"
                    fill-opacity="0.5"
                    stroke="#112A63"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </g>
            </svg>
            <span class="nav-link-text">{{ $t("orders") }}</span>
          </router-link>
        </li>
        <li :class="`nav-item ${currentRoute === 'profile' ? 'active' : null}`">
          <router-link to="/profile" class="nav-link">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Iconly/Light/Profile">
                <g id="Profile">
                  <path
                    id="Stroke 1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.7506 16.0967C8.88294 16.0967 5.58008 16.6814 5.58008 19.0233C5.58008 21.3653 8.86198 21.971 12.7506 21.971C16.6182 21.971 19.9201 21.3853 19.9201 19.0443C19.9201 16.7033 16.6391 16.0967 12.7506 16.0967Z"
                    stroke="#112A63"
                    stroke-opacity="0.5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Stroke 3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.7505 12.7564C15.2886 12.7564 17.3458 10.6983 17.3458 8.16018C17.3458 5.62208 15.2886 3.56494 12.7505 3.56494C10.2124 3.56494 8.15432 5.62208 8.15432 8.16018C8.14575 10.6897 10.1896 12.7478 12.7181 12.7564H12.7505Z"
                    stroke="#112A63"
                    stroke-opacity="0.5"
                    stroke-width="1.42857"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </g>
            </svg>
            <span class="nav-link-text">{{ $t("profile") }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
  <!-- end navbar -->
</template>

<script>
export default {
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>
