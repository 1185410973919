<template>
  <!-- 233546344021 -->
  <div class="main-wrapper min-vh-100 position-relative">
    <HeadlessNav :page="'pay with Mobile Money'" />
    <section class="tw-main">
      <div class="section-top-stack">
        <div class="tw-main-detials">
          <form class="form-include" @submit.prevent="payNow()">
            <div class="form-group top-group-img mb-4">
              <img src="/assets/img/orchard.png" alt="" />
            </div>
            <div class="form-group">
              <label for="amount" class="form-group-label">
                Amount(<img src="/assets/img/naira-sm.svg" alt="" />)
              </label>
              <div class="form-control-group form-control-splitted">
                <span class="form-splitted currency-name">{{
                  user.country.currencySymbol
                }}</span>
                <input
                  type="text"
                  class="input-control"
                  name="amount"
                  id="amount"
                  disabled
                  :value="checkout.orderTotalPrice"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="amount" class="form-group-label">
                Phone Number
              </label>
              <div class="form-control-group form-control-splitted">
                <span class="form-splitted currency-name"
                  >+{{ user.country.countryCode }}</span
                >
                <input
                  type="text"
                  class="input-control"
                  name="amount"
                  id="amount"
                  v-model="phone"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="amount" class="form-group-label"> Network </label>
              <select name="" class="form-control" v-model="network">
                <option value="AIR">AIR</option>
                <option value="TIG">TIG</option>
                <option value="VOD">VOD</option>
                <option value="MTN">MTN</option>
              </select>
            </div>
            <div class="form-group">
              <button
                type="submit"
                :disabled="loading"
                class="btn btn-secondary w-100 py-3"
              >
                <span style="font-size: 16px" v-if="!loading">Pay now</span>
                <span style="font-size: 16px" v-else>Loading...</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
    <div class="modal fade" id="orchardModalPending">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content py-0">
          <div class="popup-body p-4">
            <div
              class="modal-header d-flex justify-content-between align-items-center"
            >
              <div class="momo-img">
                <img src="@/assets/img/orchard.png" class="w-100" />
              </div>
              <div class="mo-user-details">
                <h6>
                  {{ user.country.currencySymbol
                  }}{{ checkout.orderTotalPrice }}
                </h6>
                <h6>{{ user.email }}</h6>
              </div>
            </div>
            <div class="row-block-display">
              <h6 class="text-tile text-center font-12 mb-4">
                We are confirming your payment. This could take a couple of
                minutes
              </h6>
              <div class="pending-confirmation">
                <!-- <div class="rotate"></div> -->
                <img
                  src="@/assets/img/rotate.png"
                  class="rotate"
                  width="100"
                  height="100"
                  alt=""
                />
                <span class="counter">{{ countDown }}</span>
              </div>
              <div class="payment-confirmation-block mb-4">
                <div class="payment-block payment-success mb-4">
                  <span class="text">Payment Made</span>
                  <span class="loader-done">
                    <img src="@/assets/img/group-5.svg" alt="" />
                  </span>
                </div>
                <div class="payment-block payment-pending mb-4">
                  <span class="text">Payment Confirmed</span>
                  <span class="loader-done">
                    <img
                      src="@/assets/img/89.gif"
                      width="25"
                      height="26"
                      alt=""
                    />
                  </span>
                </div>
              </div>
              <div class="text-tile-link">
                <a href="#" class="text-center text-tile mb-5">
                  Need help with the transactions?
                </a>
              </div>
              <div class="payment-lined-text">
                <img src="@/assets/img/Lock.svg" alt="" />
                <span>SECURED BY ORCHARD</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import HeadlessNav from "../../../components/dashboard/HeaderlessNav.vue";
import { mapState } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "payment-method",
  components: { HeadlessNav },
  computed: {
    ...mapState({
      user: (context) => context.user,
      checkout: (context) => context.checkout,
    }),
  },
  data() {
    return {
      loading: false,
      phone: this.$store.state.user.phoneNumber,
      note: "",
      network: "",
      countDown: 60,
    };
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    checkPaymentStatus(payment) {
      // if (payment.paymentStatus == 2) {

      var vm = this;
      var interval = setInterval(function () {
        if (vm.countDown < 10) {
          // window.ToasterAlert("error", "Outstanding transaction pending");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Outstanding transaction pending",
          });
          setTimeout(() => {
            window.location.href = "/order/" + payment.orderId;
          }, 3000);
        }
        vm.$store
          .dispatch("post", {
            endpoint:
              "services/app/Orchard/CheckPaymentStatus?PaymentId=" + payment.id,
            details: {},
          })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.result.paymentStatus == 2) {
              vm.checkPaymentStatus(resp.data.result);
            } else if (resp.data.result.paymentStatus == 3) {
              // Swal.fire({
              //   icon: "error",
              //   title: "Oops...",
              //   text: "Transaction failed, try again",
              // });
              // window.location.href = "/order/" + payment.orderId;
            } else if (resp.data.result.paymentStatus == 1) {
              window.ToasterAlert("success", "Transaction success");
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Transaction success",
              });
              window.location.href = "/order/" + payment.orderId;
            }
            clearInterval(interval);
          });
      }, 30000);
      // }
    },
    payNow() {
      if (this.phone.length < 1) {
        // window.ToasterAlert("error", "Phone number field is required");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Phone number field is required",
        });
        return false;
      }
      if (this.network.length < 1) {
        // window.ToasterAlert("error", "Network field is required");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Network field is required",
        });
        return false;
      }
      this.loading = true;
      const details = {
        amount: this.checkout.orderTotalPrice,
        currency: this.user.country.currency,
        phoneNumber: this.user.country.countryCode + this.phone,
        network: this.network,
        orderId: this.checkout.id,
      };
      console.log(details);
      this.$store
        .dispatch("post", {
          endpoint: "services/app/Orchard/InitiatePayment",
          details: details,
        })
        .then((resp) => {
          console.log(resp);
          this.loading = false;
          if (resp.data.result.paymentStatus == 3) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Transaction failed, try again",
            });
            return false;
            // window.ToasterAlert("error", "Transaction failed, try again");
          }
          if (resp.data.result.paymentStatus == 2) {
            // window.$("#orchardModalPending").modal("show");
            var myModal = new bootstrap.Modal(
              document.getElementById("orchardModalPending"),
              {}
            );
            myModal.show();
            this.countDownTimer();
            this.checkPaymentStatus(resp.data.result);
          } else {
            this.checkPaymentStatus(resp.data.result);
          }
        });
    },
  },
  created() {
    if (Object.keys(this.checkout).length === 0) {
      this.$router.push("/store");
    }
  },
};
</script>

<style>
.top-group-img {
  width: 100px;
}
.top-group-img img {
  width: 100%;
}
.tw-main {
  padding-top: 74px;
}
.counter {
  font-size: 35px;
  position: absolute;
  margin-top: -25px;
}
.rotate {
  color: white;
  animation: mymove 3s infinite;
  margin-bottom: 25px;
}
.pending-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-block {
  border: 1px solid rgba(6, 20, 53, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  margin-bottom: 14px;
}
@keyframes mymove {
  100% {
    transform: rotate(360deg);
  }
}
.text-tile-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-tile-link a {
  text-decoration: underline;
}
.payment-lined-text {
  border: 1px solid rgba(6, 20, 53, 0.2);
  border-radius: 4px;
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 70%;
}
.payment-lined-text span {
  color: #846100;
  font-size: 10px;
  font-weight: bolder;
}
.row-block-display {
  border-top: 1px solid rgba(0, 4, 45, 0.1);
  padding-top: 16px;
}
.row-block-display .text-tile {
  font-weight: 300;
  width: 75%;
  margin: 0 auto;
  color: #061435;
}
.momo-img {
  width: 90px;
}
.mo-user-details h6 {
  font-size: 12px;
  font-weight: bold;
}
.form-include .form-group {
  margin-bottom: 1.2rem;
}
.form-include .form-group .form-group-label {
  color: rgba(6, 20, 53, 0.7);
  margin-bottom: 8px;
}
.form-control-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.form-include .form-control-group .form-splitted {
  color: rgba(6, 20, 53, 0.7);
  margin-right: 15px;
  border: 0.5px solid rgba(6, 20, 53, 0.25);
  border-radius: 5px;
  width: 65px;
  height: 45px;
  line-height: 3;
  text-align: center;
}
.form-include .form-control-group .input-control {
  border: 0.5px solid rgba(6, 20, 53, 0.25);
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  height: 45px;
  line-height: 3;
}
.form-include .form-control-group .input-control:focus {
  outline: none !important;
}
.input-textarea {
  border: 0.5px solid rgba(6, 20, 53, 0.25);
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  outline: none;
}
.input-textarea::placeholder,
.form-group-label .text-blotted {
  color: rgba(6, 20, 53, 0.3);
  font-size: 12px;
}
</style>
