import Checkout from "../pages/dashboard/checkout";
import Dashboard from "../pages/dashboard/index";
import OrderDetails from "../pages/dashboard/order";
import Order from "../pages/dashboard/orders";
import PaymentMethod from "../pages/dashboard/payment-method";
import MomoPaymentMethod from "../pages/dashboard/payment/momo-payment";
import MobileMoneyPaymentMethod from "../pages/dashboard/payment/mobile-money";
import MomoPaymentMethodConfirmation from "../pages/dashboard/payment/momo-payment-confirmation";
import Profile from "../pages/dashboard/profile";
import Store from "../pages/dashboard/store";
import Products from "../pages/dashboard/products";
import BundleOffers from "../pages/dashboard/bundleOffers";
import BundleCheckout from "../pages/dashboard/bundleCheckout";

export const dashboardRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    name: "dashboard",
  },
  {
    path: "store",
    component: Store,
    name: "store",
  },
  {
    path: "products",
    component: Products,
    name: "products",
  },
  {
    path: "bundle-offers",
    component: BundleOffers,
    name: "BundleOffers",
  },
  {
    path: "bundle-checkout",
    component: BundleCheckout,
    name: "BundleCheckout",
  },
  {
    path: "order/:order",
    name: "order-details",
    component: OrderDetails,
  },
  {
    path: "order",
    name: "orders",
    component: Order,
  },
  {
    path: "profile",
    component: Profile,
    name: "profile",
  },
  {
    path: "checkout",
    component: Checkout,
    name: "checkout",
  },
  {
    path: "payment-method",
    component: PaymentMethod,
    name: "payment-method",
  },
  {
    path: "payment-method/momo/details",
    component: MomoPaymentMethod,
    name: "momo-agent",
  },
  {
    path: "payment-method/mobile-money/details",
    component: MobileMoneyPaymentMethod,
    name: "mobile-money",
  },
  {
    path: "payment-method/momo/confirmation",
    component: MomoPaymentMethodConfirmation,
    name: "payment-method",
  },
];
