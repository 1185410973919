<template>
  <Fragment>
    <div class="main-wrapper min-vh-100 position-relative">
      <NavHeaderSidebar />
      <section class="tw-main">
        <div class="tw-section tw-main-hero-top">
          <div class="tw-section-wrapper">
            <div class="tw-hero-text">
              <h4 class="hero-title-text font-20 mb-0">
                {{ $t("w_back") }}
                <span class="text-capitalize"
                  >{{ user.firstName }} {{ user.lastName }}</span
                >
              </h4>
              <p class="hero-sub-text font-14 mt-2">
                {{ $t("w_ot") }}
              </p>
            </div>
            <!-- search box group -->
            <!-- <Search /> -->
            <!-- end search box group -->
          </div>
        </div>
        <div class="store-section main-store">
          <div class="store-inner">
            <ul class="store-item-list p-0">
              <li
                class="store-list"
                v-for="(product, index) in bundleProducts"
                :key="index"
              >
                <div class="store-item">
                  <div class="item-details">
                    <div class="item-img">
                      <img :src="product.productBundle.imageURL" alt="" />
                    </div>
                    <div class="item-name">
                      <h5 class="text-uppercase">
                        {{ product.productBundle.displayName }}
                      </h5>
                      <span class=""
                        >{{ user.country.currencySymbol }}
                        {{ product.productBundle.unitPrice }}</span
                      >
                    </div>
                  </div>
                  <div class="store-counter">
                    <button
                      type="button"
                      class="btn count-increment"
                      @click="addQty(product.productBundle.id)"
                    >
                      <svg
                        width="13"
                        height="14"
                        viewBox="0 0 13 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="0.171875"
                          y1="6.89771"
                          x2="12.4219"
                          y2="6.89771"
                          stroke="white"
                          stroke-width="2.5"
                        />
                        <line
                          x1="6.04688"
                          y1="0.772705"
                          x2="6.04687"
                          y2="13.0227"
                          stroke="white"
                          stroke-width="2.5"
                        />
                      </svg>
                    </button>
                    <input
                      :id="product.productBundle.id"
                      step=".01"
                      type="number"
                      min="1"
                      class="count-value"
                      :value="product.quantity"
                      @input="changeQty(product.productBundle.id)"
                    />
                    <button
                      type="button"
                      class="btn count-decrement"
                      @click="minusQty(product.productBundle.id)"
                    >
                      <svg
                        width="13"
                        height="3"
                        viewBox="0 0 13 3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="0.171875"
                          y1="1.34668"
                          x2="12.4219"
                          y2="1.34668"
                          stroke="#061435"
                          stroke-width="2.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <footers />
      </section>
    </div>
    <NavMobile />
    <AddItemNotification :data="notification" />
  </Fragment>
</template>

<script>
  import { mapState } from "vuex";
  import NavHeaderSidebar from "../../components/dashboard/NavHeaderSidebar.vue";
  import Search from "../../components/dashboard/SearchItems.vue";
  import AddItemNotification from "../../components/dashboard/BundleOfferAddItemNotification.vue";
  import NavMobile from "../../components/dashboard/NavMobile.vue";
  import footers from "../../components/dashboard/footer.vue";
  import { Fragment } from "vue-fragment";

  export default {
    name: "store",
    components: {
      NavHeaderSidebar,
      Search,
      Fragment,
      NavMobile,
      AddItemNotification,
      footers,
    },
    computed: {
      ...mapState({
        cart: (context) => context.cart,
        user: (context) => context.user,
      }),
      calculateTotal() {
        let total = 0;
        this.cart.bundleOffers.forEach((element) => {
          let price = element.quantity * element.productBundle.unitPrice;
          total += price;
        });
        return total.toFixed(2);
      },
    },
    data() {
      return {
        products: [],
        units: [],
        convert: {},
        setTrue: true,
        notification: {
          active: false,
          price: 0,
          qty: 0,
        },
        edit: false,
        bundleProducts: [],
      };
    },
    methods: {
      changeQty(id) {
        var num = parseFloat(document.getElementById(id).value);
        if (!isNaN(num)) {
          document.getElementById(id).value = num;
          this.addToCart(id, num);
        }
      },
      checkOut(route = false) {
        var chold = this.cart.bundleOffers;
        this.$store.commit("updateBundleOfferCart", chold);
        this.setNotification();
        if (route) {
          this.$router.push("bundle-checkout");
        }
      },
      setNotification() {
        let nqty = this.cart.bundleOffers.reduce((total, num) => {
          return parseFloat(total) + parseFloat(num.quantity);
        }, 0);
        let nprice = this.cart.bundleOffers.reduce((total, num) => {
          return (
            parseFloat(total) +
            parseFloat(num.productBundle.unitPrice) * parseFloat(num.quantity)
          );
        }, 0);
        this.notification.qty = nqty;
        this.notification.edit = this.edit;
        this.notification.active = true;
        this.notification.price = this.formatPrice(nprice);
      },
      minusQty(id) {
        var num = document.getElementById(id).value;
        if (num > 0) {
          document.getElementById(id).value = parseFloat(num - 1);
          this.removeFromCart(id);
        }
      },
      addQty(id) {
        var num = document.getElementById(id).value;
        document.getElementById(id).value = parseFloat(num) + 1;
        this.addToCart(id);
      },
      async getProductBundle() {
        await this.$store
          .dispatch(
            "get",
            "services/app/ProductBundle/GetAllForCustomer?MaxResultCount=1000&customerId=" +
              this.user.id
          )
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.bundleProducts = resp.data.result.items;
              this.bundleProducts.forEach((prod) => {
                prod.quantity = 0;
              });
              if (this.cart.bundleOffers.length > 0) {
                this.bundleProducts.forEach((prod) => {
                  prod.quantity = 0;
                  this.cart.bundleOffers.forEach((item) => {
                    if (item.productBundle.id == prod.productBundle.id) {
                      prod.quantity = item.quantity;
                    }
                  });
                });
              }
            }
          });
      },
      checkCart(id) {
        var stat = false;
        this.cart.bundleOffers.forEach((prod) => {
          if (prod.productBundle.id == id) {
            prod.quantity = document.getElementById(id).value;
            stat = true;

            var result = encodeURIComponent(JSON.stringify(this.cart));
            localStorage.setItem("stockbooster_cart", result);
            this.$store.commit("updateBundleOfferCart", this.cart.bundleOffers);
            // this.notification.active = true
            // this.notification.price = prod.product.unitPrice
          }
        });
        return stat;
      },
      deleteItemFromCart(id) {
        this.$store.commit("removeFromBundleOfferCart", id);
      },
      removeFromCart(id) {
        this.cart.bundleOffers.forEach((prod) => {
          if (prod.productBundle.id == id) {
            if (prod.quantity == 1) {
              // this.cart.products.splice(index, 1)
              this.deleteItemFromCart(id);
              document.getElementById(id).value = 0;
            } else {
              prod.quantity -= 1;
            }
            var result = encodeURIComponent(JSON.stringify(this.cart));
            localStorage.setItem("stockbooster_cart", result);
            this.checkOut();
            this.$store.commit("updateBundleOfferCart", this.cart.bundleOffers);
          }
        });
      },
      addToCart(id, qty = 1) {
        var check = this.checkCart(id);
        if (!check) {
          this.bundleProducts.forEach((element) => {
            if (element.productBundle.id == id) {
              element.quantity = qty;
              this.$store.commit("addToBundleOfferCart", element);
            }
          });
        }
        this.checkOut();
      },
    },
    async created() {
      await this.getProductBundle();
      if (this.cart.bundleOffers.length > 0) {
        this.checkOut();
        this.edit = this.cart.bundleOffers[0].productBundle.id ? true : false;
        this.setNotification();
      }
    },
  };
</script>

<style scoped>
  .count-value {
    width: 45px;
    text-align: center;
    border: 1px solid #e6e7ea;
    border-radius: 0;
    outline: none;
    height: 30px;
  }
  .store-list .store-item .item-details .item-img {
    width: 74px;
    min-width: 74px;
  }
  .store-list .store-item .item-details .item-img img {
    width: 100%;
    object-fit: contain;
  }
  .vue-select-group select {
    border: none;
    color: rgba(6, 20, 53, 0.7);
    background: #fff;
    margin-left: -3px;
  }
</style>
