<template>
  <Fragment>
    <Fragment v-if="loaded">
      <div class="main-wrapper min-vh-100 position-relative">
        <NavHeaderSidebar />
        <section class="tw-main">
          <div class="tw-section tw-main-hero-top">
            <div class="tw-section-wrapper">
              <div class="tw-hero-text">
                <h4 class="hero-title-text font-20 mb-0">
                  {{ $t("w_back") }}
                  <span class="text-capitalize"
                    >{{ user.firstName }} {{ user.lastName }}</span
                  >
                </h4>
                <p class="hero-sub-text font-14 mt-2">
                  {{ $t("w_ot") }}
                </p>
              </div>
              <!-- search box group -->
              <!-- <Search /> -->
              <!-- end search box group -->
            </div>
          </div>
          <div class="store-section main-store">
            <!-- Advert section, might need it later -->
            <!-- <div class="row mb-3" v-if="user.country.tenantId == 10">
            <div class="col-6">
              <div class="ad-img">
                <img src="@/assets/img/5.jpeg" class="w-100 h-100" />
              </div>
            </div>
            <div class="col-6">
              <div class="ad-img">
                <img src="@/assets/img/5.jpeg" class="w-100 h-100" />
              </div>
            </div>
          </div> -->
            <div class="store-inner">
              <ul class="store-item-list p-0" v-if="products.length > 0">
                <li
                  class="store-list"
                  v-for="product in products"
                  :key="product.id"
                >
                  <div class="store-item">
                    <div class="item-details">
                      <div class="item-img">
                        <img :src="product.product.imageURL" alt="" />
                      </div>
                      <div class="item-name">
                        <h5 class="text-uppercase">
                          {{ product.product.productName }}
                        </h5>
                        <div class="vue-select-group">
                          <!-- <v-select :options="vueData"></v-select> -->
                          <select
                            :id="product.product.id"
                            :class="product.product.productCode"
                            @change="
                              updateProductUnitId(
                                $event,
                                product.product.productCode
                              )
                            "
                            class="input-bordered required valid s-drop"
                          >
                            <option
                              v-for="(unit, key) in product.productPrices"
                              :key="key"
                              :value="unit.productPrice.id"
                              :selected="
                                unit.productUnitName == product.cartUnitName
                                  ? setTrue
                                  : !setTrue
                              "
                            >
                              {{ unit.productUnitName }}
                            </option>
                          </select>
                        </div>
                        <span
                          class="mt-2"
                          v-if="getSelectedProductPrice(product.product.id) > 0"
                          >{{ $store.state.user.country.currencySymbol
                          }}{{
                            getSelectedProductPrice(product.product.id)
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="store-counter">
                      <button
                        type="button"
                        class="btn count-increment"
                        @click="addQty(product.product.productCode)"
                      >
                        <svg
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="0.171875"
                            y1="6.89771"
                            x2="12.4219"
                            y2="6.89771"
                            stroke="white"
                            stroke-width="2.5"
                          />
                          <line
                            x1="6.04688"
                            y1="0.772705"
                            x2="6.04687"
                            y2="13.0227"
                            stroke="white"
                            stroke-width="2.5"
                          />
                        </svg>
                      </button>
                      <input
                        :id="product.product.productCode"
                        step=".01"
                        type="number"
                        min="1"
                        class="count-value"
                        :value="product.quantity"
                        @input="changeQty(product.product.productCode)"
                      />
                      <button
                        type="button"
                        class="btn count-decrement"
                        @click="minusQty(product.product.productCode)"
                      >
                        <svg
                          width="13"
                          height="3"
                          viewBox="0 0 13 3"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="0.171875"
                            y1="1.34668"
                            x2="12.4219"
                            y2="1.34668"
                            stroke="#061435"
                            stroke-width="2.5"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <p v-else class="alert alert-info">{{ $t("n_pa") }}</p>
            </div>
          </div>

          <footers />
        </section>
      </div>
      <NavMobile />
      <AddItemNotification :data="notification" />
    </Fragment>
    <Loader v-else />
  </Fragment>
</template>

<script>
  import { mapState } from "vuex";
  import NavHeaderSidebar from "../../components/dashboard/NavHeaderSidebar.vue";
  import Search from "../../components/dashboard/SearchItems.vue";
  import AddItemNotification from "../../components/dashboard/AddItemNotification.vue";
  import NavMobile from "../../components/dashboard/NavMobile.vue";
  import footers from "../../components/dashboard/footer.vue";
  import { Fragment } from "vue-fragment";
  import Loader from "@/components/loader.vue";

  export default {
    name: "store",
    components: {
      NavHeaderSidebar,
      Search,
      Fragment,
      NavMobile,
      AddItemNotification,
      footers,
      Loader,
    },
    computed: {
      ...mapState({
        cart: (context) => context.cart,
        user: (context) => context.user,
      }),
      calculateTotal() {
        let total = 0;
        this.cart.products.forEach((element) => {
          let price = element.quantity * element.convertedPrice;
          total += price;
        });
        return total.toFixed(2);
      },
    },
    data() {
      return {
        products: [],
        units: [],
        convert: {},
        setTrue: true,
        notification: {
          active: false,
          price: 0,
          qty: 0,
        },
        edit: false,
        productPrices: [],
        loaded: false,
      };
    },
    methods: {
      getSelectedProductPrice(id) {
        var total = 0;
        this.cart.products.forEach((item) => {
          if (item.product.id == id) {
            total = item.quantity * item.convertedPrice;
          }
        });
        return total.toFixed(2);
      },
      changeQty(id) {
        var num = parseFloat(document.getElementById(id).value);
        if (!isNaN(num)) {
          document.getElementById(id).value = num;
          this.addToCart(id, num);
        }
      },
      updateProductUnitId(event, productCode) {
        this.products.forEach((product) => {
          var pp = product.productPrices.filter(
            (prod) => prod.productPrice.id == event.target.value
          );
          if (pp.length > 0) {
            this.productPrices[product.product.productCode] = pp[0];
          }
        });
        this.cart.products.forEach((product) => {
          if (product.product.productCode == productCode) {
            product.prodUnitId =
              this.productPrices[
                product.product.productCode
              ].productPrice.productUnitId;
            product.cartUnitName =
              this.productPrices[product.product.productCode].productUnitName;
            product.convertedPrice =
              this.productPrices[
                product.product.productCode
              ].productPrice.unitPrice;
          }
        });
        this.checkOut();
        this.$store.commit("updateCart", this.cart.products);
      },
      checkOut(route = false) {
        var chold = this.cart.products;
        chold.forEach((product) => {
          product.prodUnitId =
            this.productPrices[
              product.product.productCode
            ].productPrice.productUnitId;
          product.cartUnitName =
            this.productPrices[product.product.productCode].productUnitName;
          product.convertedPrice =
            this.productPrices[
              product.product.productCode
            ].productPrice.unitPrice;
        });
        this.$store.commit("updateCart", chold);
        this.setNotification();
        if (route) {
          this.$router.push("checkout");
        }
      },
      setNotification() {
        let nqty = this.cart.products.reduce((total, num) => {
          return parseFloat(total) + parseFloat(num.quantity);
        }, 0);
        let nprice = this.cart.products.reduce((total, num) => {
          return (
            parseFloat(total) +
            parseFloat(num.convertedPrice) * parseFloat(num.quantity)
          );
        }, 0);
        this.notification.qty = nqty;
        this.notification.edit = this.edit;
        this.notification.active = true;
        this.notification.price = this.formatPrice(nprice);
      },
      minusQty(id) {
        var num = document.getElementById(id).value;
        if (num > 0) {
          document.getElementById(id).value = parseFloat(num - 1);
          this.removeFromCart(id);
        }
      },
      addQty(id) {
        var num = document.getElementById(id).value;
        document.getElementById(id).value = parseFloat(num) + 1;
        this.addToCart(id);
      },
      async getProducts() {
        await this.$store
          .dispatch(
            "getProduct",
            "services/app/Products/GetAllForCustomer?MaxResultCount=1000&customerId=" +
              this.user.id
          )
          .then((resp) => {
            // console.log(resp);
            if (resp.data.success) {
              this.loaded = true;
              this.products = resp.data.result.items;
              if (this.cart.products.length > 0) {
                this.products.forEach((prod) => {
                  this.productPrices[prod.product.productCode] =
                    prod.productPrices[0];
                  prod.quantity = 0;
                  this.cart.products.forEach((item) => {
                    if (item.product.productCode == prod.product.productCode) {
                      prod.quantity = item.quantity;
                      prod.cartUnitName = item.cartUnitName;
                      setTimeout(() => {
                        var unitId = document.getElementById(item.product.id);
                        unitId.value = item.prodUnitId;
                      }, 1000);

                      // document.getElementsByClassName(item.product.productCode)[0].value = item.prodUnitId
                    }
                  });
                });
              } else {
                this.products.forEach((prod) => {
                  this.productPrices[prod.product.productCode] =
                    prod.productPrices[0];
                  prod.quantity = 0;
                });
              }
              // console.log(this.products);
            }
          });
      },
      checkCart(id) {
        var stat = false;
        this.cart.products.forEach((prod) => {
          if (prod.product.productCode == id) {
            prod.quantity = document.getElementById(id).value;
            //check again if user has change the product unit
            prod.prodUnitId =
              this.productPrices[
                prod.product.productCode
              ].productPrice.productUnitId;
            stat = true;

            var result = encodeURIComponent(JSON.stringify(this.cart));
            localStorage.setItem("stockbooster_cart", result);
            this.$store.commit("updateCart", this.cart.products);
            // this.notification.active = true
            // this.notification.price = prod.product.unitPrice
          }
        });
        return stat;
      },
      deleteItemFromCart(id) {
        this.$store.commit("removeFromCart", id);
      },
      removeFromCart(id) {
        this.cart.products.forEach((prod) => {
          if (prod.product.productCode == id) {
            if (prod.quantity == 1) {
              // this.cart.products.splice(index, 1)
              this.deleteItemFromCart(id);
              document.getElementById(id).value = 0;
            } else {
              prod.quantity -= 1;
            }
            var result = encodeURIComponent(JSON.stringify(this.cart));
            localStorage.setItem("stockbooster_cart", result);
            this.checkOut();
            this.$store.commit("updateCart", this.cart.products);
          }
        });
      },
      addToCart(id, qty = 1) {
        var check = this.checkCart(id);
        if (!check) {
          this.products.forEach((element) => {
            if (element.product.productCode == id) {
              element.quantity = qty;
              element.prodUnitId =
                this.productPrices[
                  element.product.productCode
                ].productPrice.productUnitId;
              this.$store.commit("addToCart", element);
            }
          });
        }
        this.checkOut();
      },
      async getUnits() {
        await this.$store
          .dispatch("getWithTalent", "services/app/ProductUnits/GetAll")
          .then((resp) => {
            if (resp.data.success) {
              this.units = resp.data.result.items;
            }
          });
      },
    },
    async created() {
      await this.getUnits();
      await this.getProducts();
      if (this.cart.products.length > 0) {
        this.checkOut();
        this.edit = this.cart.products[0].product.orderId ? true : false;
        this.setNotification();
      }
    },
  };
</script>

<style scoped>
  .ad-img {
    height: 100px;
  }
  .ad-img img {
    object-fit: contain;
  }
  .count-value {
    width: 45px;
    text-align: center;
    border: 1px solid #e6e7ea;
    border-radius: 0;
    outline: none;
    height: 30px;
  }
  .store-list .store-item .item-details .item-img {
    width: 74px;
    min-width: 74px;
  }
  .store-list .store-item .item-details .item-img img {
    width: 100%;
    object-fit: contain;
  }
  .vue-select-group select {
    border: none;
    color: rgba(6, 20, 53, 0.7);
    background: #fff;
    margin-left: -3px;
  }
</style>
