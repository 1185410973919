<template>
  <div class="main-wrapper position-relative">
    <!-- end navbar side and top -->
    <section class="tw-main py-5" style="min-height: 100vh">
      <div class="tw-section tw-main-hero-top">
        <div class="tw-section-wrapper">
          <div class="col-5 mb-5">
            <a href="">
              <div class="nav-brand">
                <img
                  src="/assets/img/logo.png"
                  class="img-fluid-custom"
                  alt="logo"
                />
              </div>
            </a>
          </div>
          <div class="tw-hero-text">
            <p class="hero-sub-text font-16">{{ $t("login_cont") }}</p>
          </div>
        </div>
      </div>
      <div class="form-wrapper-group ml-content mt-0">
        <form @submit.prevent="getToken()">
          <div class="input-item input-with-label mb-3">
            <div class="relative">
              <div class="select-wrapper">
                <span class="country-select-flag">
                  <img
                    :src="`/assets/img/flag/${
                      country_code ? country_code : 'flag'
                    }.png`"
                    alt="flag.png"
                  />
                </span>
                <select
                  @change="getCountryCode($event)"
                  class="input-bordered country-select-input padding-icon-left input-height"
                  id="nationality"
                  style="background: #fff"
                >
                  <!-- :selected="code.tenantName == ipCountry ? 'selected' : ''" -->
                  <option default>{{ $t("select_c") }}</option>
                  <option
                    v-for="code in data"
                    :key="code.tenantId"
                    :value="code.tenantId"
                  >
                    {{ code.tenantName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="input-item input-with-label mb-3">
            <div class="relative">
              <span class="phone-image">
                <img src="/icons/Lock.svg" alt="img.svg" />
              </span>
              <input
                class="input-bordered padding-icon-left padding-icon-right input-height"
                v-model="phone"
                type="password"
                id="secret_input"
                :placeholder="$t('en_uid')"
              />
              <button type="button" @click="toggleID" class="btn show-button">
                <span class="font-12" v-if="showPass">{{ $t("show") }}</span>
                <span class="font-12" v-else>{{ $t("hide") }}</span>
              </button>
            </div>
          </div>
          <div class="form-group form-checkbox mb-3">
            <input required type="checkbox" name="confirm" id="confirm" />
            <label for="confirm">
              {{ $t("cond") }}
            </label>
          </div>
          <div class="form-group form-checkbox mb-3">
            <input required type="checkbox" name="confirm-2" id="confirm-2" />
            <label for="confirm-2">
              {{ $t("conf") }}
              <a href="#">{{ $t("stoc") }}</a> {{ $t("of") }}
              <a href="#">{{ $t("pric") }}</a>
            </label>
          </div>
          <div class="form-group button-group">
            <button
              class="btn btn-secondary form-button py-3 w-100"
              type="submit"
            >
              <span class="font-16" v-if="!loading">{{ $t("login") }}</span>
              <span class="font-16" v-else>{{ $t("loading") }}</span>
            </button>
          </div>
        </form>
      </div>

      <div class="text-center mb-3">
        <language />
      </div>
      <div style="height: 70px">
        <img
          src="/assets/img/fimg3.png"
          class="w-100 h-100"
          style="object-fit: contain"
        />
      </div>
    </section>

    <pwa />
  </div>
</template>
<script>
  import axios from "axios";
  import { mapState } from "vuex";
  import pwa from "./pwa";
  export default {
    computed: {
      ...mapState({
        userState: (context) => context.user,
      }),
    },
    components: { pwa },
    data() {
      return {
        data: [],
        user: [],
        country_code: "",
        phone: "",
        token: "",
        loading: false,
        country: {},
        ipCountry: "",
        showPass: true,
        loaded: false,
      };
    },
    methods: {
      getCountryCode(event) {
        const talentId = event.target.value;

        this.data.forEach((element) => {
          if (element.tenantId == talentId) {
            this.country = element;
            this.country_code = element.urnoPrefix;
          }
        });
      },
      login() {
        this.$store
          .dispatch(
            "get",
            "services/app/Customers/GetCustomerListByURNO?urno=" +
              this.country.urnoPrefix +
              "" +
              this.phone
          )
          .then((resp) => {
            this.loading = false;
            if (resp.status == 200) {
              // var data = resp.data.result.customer;
              // data.token = this.$store.state.token;
              // data.country = this.country;
              // this.$store.commit("setUser", data);
              // this.$router.push("/store");
              var data = {
                ...resp.data.result[0].customer,
                country: this.country,
                token: this.$store.state.token,
                outlets: resp.data.result,
              };
              this.$store.commit("setUser", data);
              if (resp.data.result.length > 1) {
                this.$router.push("/outlets");
              } else {
                this.$router.push("/store");
              }
            }
          });
        this.loading = false;
      },
      getToken() {
        if (this.userState) {
          if (this.userState.token) {
            this.$router.push("/dashboard");
          }
        } else {
          if (this.phone.length < 1) {
            // window.ToasterAlert("error", "Phone field is required");
            return false;
          }
          this.loading = true;
          this.$store
            .dispatch("post", {
              endpoint: "TokenAuth/Authenticate",
              details: {
                userNameOrEmailAddress: "webapp@mail.com",
                password: "Password123*",
                tenantId: this.country.tenantId,
              },
            })
            .then((resp) => {
              if (resp.status == 200) {
                this.token = resp.data.result.accessToken;
                this.$store.commit("setToken", this.token);
                this.login();
              }
            })
            .catch((error) => {
              this.$store.dispatch("handleError", error);
            });
        }
      },
      toggleID() {
        this.showPass = !this.showPass;

        const pass = document.querySelector("#secret_input");
        if (pass.getAttribute("type") == "password") {
          pass.setAttribute("type", "text");
        } else {
          pass.setAttribute("type", "password");
        }
      },
      getMarket() {
        this.$store
          .dispatch("get", "services/app/EndMarkets/GetEndMarkets")
          .then((resp) => {
            if (resp.status == 200) {
              this.data = resp.data.result;
              this.getIp();
            }
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
      getIp() {
        axios
          .get(
            "https://api.bigdatacloud.net/data/ip-geolocation?key=6e359050e938481b9853b2a7ca512bde"
          )
          .then((resp) => {
            // console.log(resp)
            this.ipCountry = resp.data.country.name;
            this.data.forEach((item) => {
              if (item.tenantName == resp.data.country.name) {
                this.country_code = item.urnoPrefix;
              }
              if (item.urnoPrefix == this.country_code) {
                this.country = item;
              }
            });
          });
      },
    },
    created() {
      this.$store.commit("getUser");
      // console.log(this.userState);
      this.getMarket();
    },
  };
</script>
<style scoped>
  .input-bordered {
    border-radius: 4px;
    border: 0.5px solid rgba(6, 20, 53, 0.15);
    width: 100%;
    padding: 10px 15px;
    line-height: 20px;
    font-size: 0.9em;
    color: rgba(73, 84, 99, 0.7);
    transition: all 0.4s;
  }
  .input-bordered::-webkit-input-placeholder {
    color: #758698;
  }
  .input-bordered::-moz-placeholder {
    color: #758698;
  }
  .input-bordered:-ms-input-placeholder {
    color: #758698;
  }
  .input-bordered:-moz-placeholder {
    color: #758698;
  }
  .input-bordered:focus {
    box-shadow: none;
    outline: none;
    border-color: #b1becc;
  }
  .input-bordered:disabled {
    background: rgba(230, 239, 251, 0.2);
  }
  .input-bordered ~ .error {
    color: #ff6868;
    margin-bottom: 0;
    position: relative;
    top: 7px;
  }
  .validate-modern .input-bordered ~ .error {
    position: absolute;
    right: -10px;
    top: -15px;
    background: #ff6868;
    color: #fff;
    font-size: 11px;
    line-height: 18px;
    padding: 2px 10px;
    border-radius: 2px;
  }
  .validate-modern .input-bordered ~ .error:after {
    position: absolute;
    content: "";
    height: 0;
    width: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid #ff6868;
    border-bottom: 5px solid transparent;
    bottom: -5px;
    left: 3px;
  }

  .page-ath-wrap {
    display: flex;
    min-height: 100vh;
  }
  .page-ath-content {
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (min-width: 768px) {
    .page-ath-content {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .page-ath-content {
      width: 38%;
    }
  }
  .page-ath-form,
  .page-ath-header,
  .page-ath-footer,
  .page-ath-text {
    width: 440px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 30px;
  }
  .page-ath-header {
    padding-top: 40px;
  }
  .input-item {
    position: relative;
    padding-bottom: 20px;
  }
  .input-item-sm {
    padding-bottom: 6px;
  }
  .input-item-label {
    font-size: 14px;
    font-weight: 500;
    color: #495463;
    line-height: 1.1;
    margin-bottom: 12px;
    display: inline-block;
  }
  .relative {
    position: relative;
  }
  .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    opacity: 0.7;
  }
  .input-icon-left {
    left: 15px;
  }
  .input-icon-left ~ .input-solid,
  .input-icon-left + .input-bordered {
    padding-left: 35px;
  }
  .input-icon-right {
    right: 15px;
    left: auto;
  }
  .input-icon-right ~ .input-solid,
  .input-icon-right + .input-bordered {
    padding-right: 30px;
  }
  .select-wrapper {
    position: relative;
  }
  /* .select-wrapper:after { position: absolute; height: 42px; width: 44px; line-height: 44px; text-align: center; top: 50%; right: 0; font-family: 'themify'; transform: translateY(-50%); content: '\e64b'; font-size: 12px; } */

  @media (min-width: 576px) {
    .input-icon-lg {
      font-size: 18px;
      line-height: 15px;
      left: 20px;
    }
    .input-icon-left ~ .input-solid,
    .input-icon-left + .input-bordered {
      padding-left: 50px;
    }
    .input-icon-right {
      right: 15px;
      left: auto;
    }
    .input-icon-right ~ .input-solid,
    .input-icon-right + .input-bordered {
      padding-right: 50px;
    }
    .input-bordered ~ .error,
    .input-solid ~ .error,
    .input-checkbox ~ .error,
    .input-radio ~ .error {
      font-size: 12px;
    }
  }

  label {
    line-height: 19px !important;
  }
  .s-drop {
    padding-left: 35px;
  }
  .page-ath-form,
  .page-ath-header,
  .page-ath-footer,
  .page-ath-text {
    width: 440px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 30px;
  }
  .page-ath-form {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .page-ath-content {
    box-shadow: 0 5px 10px rgb(154 160 185 / 5%),
      0 15px 40px rgb(166 173 201 / 20%);
  }
  @media (min-width: 576px) {
    .s-drop {
      padding-left: 50px;
    }
  }
  .phone-image,
  .country-select-flag {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: #f0f0f0;
    width: 60px;
    border: 0.5px solid rgba(6, 20, 53, 0.15);
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .show-button {
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 55px;
    border: none;
    background: transparent !important;
  }
  .show-button span {
    color: #061435;
    padding-right: 10px;
  }
  .phone-image img {
    width: 30px;
  }
  .input-height {
    height: 50px;
  }
  .padding-icon-left {
    padding-left: 75px;
  }
  .padding-icon-right {
    padding-right: 60px;
  }
  .form-checkbox input {
    width: 30px;
    height: 30px;
    border: 1px solid rgba(6, 20, 53, 0.4);
    border-radius: 4px;
    filter: #0b1e48;
  }
  .form-checkbox input#confirm-2 {
    width: 35px;
    height: 35px;
  }
  .country-select-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
  }
</style>
