<template>
  <div class="main-wrapper min-vh-100 position-relative">
    <HeadlessNav :page="'payment methods'" />
    <section class="tw-main">
      <div class="section-top-stack">
        <div class="tw-main-detials">
          <ul class="details-list-items">
            <li
              v-for="(item, index) in $store.state.user.country.paymentMethods"
              :key="index"
            >
              <div v-if="item != 5 && item != 6">
                <div
                  class="list-item-group detail-list-item border-light-custom border-radius-5"
                  @click.prevent="setPaymentMethod('payment on delivery')"
                  v-if="item == 0"
                >
                  <div class="side-list">
                    <div class="icon-class" style="width: 104px">
                      <img
                        src="/assets/img/cash-on-delivery.png"
                        class="w-100"
                      />
                    </div>
                    <div class="item-grouping">
                      <h5 class="text-capitalize">cash on delivery</h5>
                      <p class="text-secondary-light font-12">
                        Pay by cash (Please don't make any transfer to delivery
                        agent accounts)
                      </p>
                    </div>
                  </div>
                  <div class="item-action-group">
                    <button
                      type="button"
                      class="btn text-primary text-capitalize"
                    >
                      <svg
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.7192 8.33617L3.13687 0.771095C2.9615 0.595982 2.72753 0.499512 2.47816 0.499512C2.22879 0.499512 1.99502 0.595982 1.82 0.771095L1.26272 1.32808C0.900094 1.69102 0.900681 2.2809 1.26403 2.64329L7.63108 8.99598L1.2696 15.3557C1.09458 15.5308 0.99808 15.7643 0.998327 16.0132C0.998575 16.2624 1.09554 16.4958 1.27091 16.6711L1.82929 17.2279C2.0048 17.403 2.23863 17.4995 2.488 17.4995C2.73737 17.4995 2.97115 17.403 3.14617 17.2279L10.7205 9.65594C10.8959 9.48027 10.9922 9.24573 10.9914 8.9964C10.9917 8.7461 10.895 8.5117 10.7192 8.33617Z"
                          fill="#061435"
                          fill-opacity="0.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div
                  class="list-item-group detail-list-item border-light-custom border-radius-5"
                  @click.prevent="setPaymentMethod('credit')"
                  v-if="item == 1"
                >
                  <div class="side-list">
                    <div class="icon-class">
                      <img src="@/assets/img/credit.png" class="w-100" />
                    </div>
                    <div class="item-grouping">
                      <h5 class="text-capitalize">Buy on Credit</h5>
                      <p class="text-secondary-light font-12">
                        Buy now pay later
                      </p>
                    </div>
                  </div>
                  <div class="item-action-group">
                    <button
                      type="button"
                      class="btn text-primary text-capitalize"
                    >
                      <svg
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.7192 8.33617L3.13687 0.771095C2.9615 0.595982 2.72753 0.499512 2.47816 0.499512C2.22879 0.499512 1.99502 0.595982 1.82 0.771095L1.26272 1.32808C0.900094 1.69102 0.900681 2.2809 1.26403 2.64329L7.63108 8.99598L1.2696 15.3557C1.09458 15.5308 0.99808 15.7643 0.998327 16.0132C0.998575 16.2624 1.09554 16.4958 1.27091 16.6711L1.82929 17.2279C2.0048 17.403 2.23863 17.4995 2.488 17.4995C2.73737 17.4995 2.97115 17.403 3.14617 17.2279L10.7205 9.65594C10.8959 9.48027 10.9922 9.24573 10.9914 8.9964C10.9917 8.7461 10.895 8.5117 10.7192 8.33617Z"
                          fill="#061435"
                          fill-opacity="0.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div
                  class="list-item-group detail-list-item border-light-custom border-radius-5"
                  @click.prevent="setPaymentMethod('pos')"
                  v-if="item == 2"
                >
                  <div class="side-list">
                    <div class="icon-class">
                      <img src="@/assets/img/pos.png" class="w-100" />
                    </div>
                    <div class="item-grouping">
                      <h5 class="text-capitalize">Pay with POS</h5>
                      <p class="text-secondary-light font-12">Pay with POS</p>
                    </div>
                  </div>
                  <div class="item-action-group">
                    <button
                      type="button"
                      class="btn text-primary text-capitalize"
                    >
                      <svg
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.7192 8.33617L3.13687 0.771095C2.9615 0.595982 2.72753 0.499512 2.47816 0.499512C2.22879 0.499512 1.99502 0.595982 1.82 0.771095L1.26272 1.32808C0.900094 1.69102 0.900681 2.2809 1.26403 2.64329L7.63108 8.99598L1.2696 15.3557C1.09458 15.5308 0.99808 15.7643 0.998327 16.0132C0.998575 16.2624 1.09554 16.4958 1.27091 16.6711L1.82929 17.2279C2.0048 17.403 2.23863 17.4995 2.488 17.4995C2.73737 17.4995 2.97115 17.403 3.14617 17.2279L10.7205 9.65594C10.8959 9.48027 10.9922 9.24573 10.9914 8.9964C10.9917 8.7461 10.895 8.5117 10.7192 8.33617Z"
                          fill="#061435"
                          fill-opacity="0.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div
                  class="list-item-group detail-list-item border-light-custom border-radius-5"
                  @click.prevent="setPaymentMethod('transfer')"
                  v-if="item == 3"
                >
                  <div class="side-list">
                    <div class="icon-class">
                      <img src="@/assets/img/transfer.png" class="w-100" />
                    </div>
                    <div class="item-grouping">
                      <h5 class="text-capitalize">Pay with Transfer</h5>
                      <p class="text-secondary-light font-12">
                        Pay with bank transfer
                      </p>
                    </div>
                  </div>
                  <div class="item-action-group">
                    <button
                      type="button"
                      class="btn text-primary text-capitalize"
                    >
                      <svg
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.7192 8.33617L3.13687 0.771095C2.9615 0.595982 2.72753 0.499512 2.47816 0.499512C2.22879 0.499512 1.99502 0.595982 1.82 0.771095L1.26272 1.32808C0.900094 1.69102 0.900681 2.2809 1.26403 2.64329L7.63108 8.99598L1.2696 15.3557C1.09458 15.5308 0.99808 15.7643 0.998327 16.0132C0.998575 16.2624 1.09554 16.4958 1.27091 16.6711L1.82929 17.2279C2.0048 17.403 2.23863 17.4995 2.488 17.4995C2.73737 17.4995 2.97115 17.403 3.14617 17.2279L10.7205 9.65594C10.8959 9.48027 10.9922 9.24573 10.9914 8.9964C10.9917 8.7461 10.895 8.5117 10.7192 8.33617Z"
                          fill="#061435"
                          fill-opacity="0.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div
                  class="list-item-group detail-list-item border-light-custom border-radius-5"
                  @click.prevent="setPaymentMethod('mobileMoney')"
                  v-if="item == 4"
                >
                  <div class="side-list">
                    <div class="icon-class">
                      <img src="/assets/img/orchard.png" class="w-100" />
                    </div>
                    <div class="item-grouping">
                      <h5 class="text-capitalize">Pay with Mobile Money</h5>
                      <p class="text-secondary-light font-12">
                        Pay with mobile money and await confirmation.
                      </p>
                    </div>
                  </div>
                  <div class="item-action-group">
                    <button
                      type="button"
                      class="btn text-primary text-capitalize"
                    >
                      <svg
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.7192 8.33617L3.13687 0.771095C2.9615 0.595982 2.72753 0.499512 2.47816 0.499512C2.22879 0.499512 1.99502 0.595982 1.82 0.771095L1.26272 1.32808C0.900094 1.69102 0.900681 2.2809 1.26403 2.64329L7.63108 8.99598L1.2696 15.3557C1.09458 15.5308 0.99808 15.7643 0.998327 16.0132C0.998575 16.2624 1.09554 16.4958 1.27091 16.6711L1.82929 17.2279C2.0048 17.403 2.23863 17.4995 2.488 17.4995C2.73737 17.4995 2.97115 17.403 3.14617 17.2279L10.7205 9.65594C10.8959 9.48027 10.9922 9.24573 10.9914 8.9964C10.9917 8.7461 10.895 8.5117 10.7192 8.33617Z"
                          fill="#061435"
                          fill-opacity="0.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div
                  class="list-item-group detail-list-item border-light-custom border-radius-5"
                  @click.prevent="setPaymentMethod('opay')"
                  v-if="item == 5"
                >
                  <div class="side-list">
                    <div class="icon-class">
                      <img src="@/assets/img/opay.png" class="w-100" />
                    </div>
                    <div class="item-grouping">
                      <h5 class="text-capitalize">Pay with Opay</h5>
                      <p class="text-secondary-light font-12">
                        Pay with OPay Digital Services Limited (OPay)
                      </p>
                    </div>
                  </div>
                  <div class="item-action-group">
                    <button
                      type="button"
                      class="btn text-primary text-capitalize"
                    >
                      <svg
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.7192 8.33617L3.13687 0.771095C2.9615 0.595982 2.72753 0.499512 2.47816 0.499512C2.22879 0.499512 1.99502 0.595982 1.82 0.771095L1.26272 1.32808C0.900094 1.69102 0.900681 2.2809 1.26403 2.64329L7.63108 8.99598L1.2696 15.3557C1.09458 15.5308 0.99808 15.7643 0.998327 16.0132C0.998575 16.2624 1.09554 16.4958 1.27091 16.6711L1.82929 17.2279C2.0048 17.403 2.23863 17.4995 2.488 17.4995C2.73737 17.4995 2.97115 17.403 3.14617 17.2279L10.7205 9.65594C10.8959 9.48027 10.9922 9.24573 10.9914 8.9964C10.9917 8.7461 10.895 8.5117 10.7192 8.33617Z"
                          fill="#061435"
                          fill-opacity="0.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div
                  class="list-item-group detail-list-item border-light-custom border-radius-5"
                  @click.prevent="setPaymentMethod('paystack')"
                  v-if="item == 6"
                >
                  <div class="side-list">
                    <div class="icon-class">
                      <img src="@/assets/img/paystack.png" class="w-100" />
                    </div>
                    <div class="item-grouping">
                      <h5 class="text-capitalize">Pay with Paystack</h5>
                      <p class="text-secondary-light font-12">
                        Pay with Paystack
                      </p>
                    </div>
                  </div>
                  <div class="item-action-group">
                    <button
                      type="button"
                      class="btn text-primary text-capitalize"
                    >
                      <svg
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.7192 8.33617L3.13687 0.771095C2.9615 0.595982 2.72753 0.499512 2.47816 0.499512C2.22879 0.499512 1.99502 0.595982 1.82 0.771095L1.26272 1.32808C0.900094 1.69102 0.900681 2.2809 1.26403 2.64329L7.63108 8.99598L1.2696 15.3557C1.09458 15.5308 0.99808 15.7643 0.998327 16.0132C0.998575 16.2624 1.09554 16.4958 1.27091 16.6711L1.82929 17.2279C2.0048 17.403 2.23863 17.4995 2.488 17.4995C2.73737 17.4995 2.97115 17.403 3.14617 17.2279L10.7205 9.65594C10.8959 9.48027 10.9922 9.24573 10.9914 8.9964C10.9917 8.7461 10.895 8.5117 10.7192 8.33617Z"
                          fill="#061435"
                          fill-opacity="0.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <!-- <li class="detail-list-item border-light-custom border-radius-5">
              <div
                class="list-item-group"
                @click.prevent="setPaymentMethod('momo agent')"
              >
                <div class="side-list info">
                  <span
                    class="icon-class d-flex justify-content-center align-items-center"
                    style="min-width: 65px"
                  >
                    <img src="/assets/img/momo-payment.svg" alt="" />
                  </span>
                  <div class="item-grouping">
                    <h5 class="text-capitalize">Pay with Momo Agent</h5>
                    <p class="text-secondary-light font-12">
                      Pay with mobile money and await confirmation.
                    </p>
                  </div>
                </div>
                <div class="item-action-group">
                  <button
                    type="button"
                    class="btn text-primary text-capitalize"
                  >
                    <svg
                      width="11"
                      height="18"
                      viewBox="0 0 11 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7192 8.33617L3.13687 0.771095C2.9615 0.595982 2.72753 0.499512 2.47816 0.499512C2.22879 0.499512 1.99502 0.595982 1.82 0.771095L1.26272 1.32808C0.900094 1.69102 0.900681 2.2809 1.26403 2.64329L7.63108 8.99598L1.2696 15.3557C1.09458 15.5308 0.99808 15.7643 0.998327 16.0132C0.998575 16.2624 1.09554 16.4958 1.27091 16.6711L1.82929 17.2279C2.0048 17.403 2.23863 17.4995 2.488 17.4995C2.73737 17.4995 2.97115 17.403 3.14617 17.2279L10.7205 9.65594C10.8959 9.48027 10.9922 9.24573 10.9914 8.9964C10.9917 8.7461 10.895 8.5117 10.7192 8.33617Z"
                        fill="#061435"
                        fill-opacity="0.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import HeadlessNav from "../../components/dashboard/HeaderlessNav.vue";
  import Swal from "sweetalert2";
  export default {
    name: "payment-method",
    components: { HeadlessNav },
    methods: {
      setPaymentMethod(method) {
        this.$store.commit("updatePaymentMethod", method);
        this.$router.push("/checkout");
      },
    },
    created() {
      if (this.$store.state.cart.products.length < 1) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No product in your cart",
        });
        this.$router.push("/store");
      }
    },
  };
</script>

<style scoped>
  .side-list {
    display: flex;
  }
  .side-list .icon-class {
    width: 65px;
    max-width: 65px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .detail-list-item {
    display: flex;
    justify-content: space-between;
    padding: 15px 8px;
    align-items: center;
  }
</style>
