<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "dashboard-layout",
  created() {
    this.$store.commit("getUser");
    if (!this.$store.state.user) {
      this.$store.commit("logout");
    }
  },
};
</script>

<style>
.swal2-title {
  font-size: 20px !important;
}
</style>
