<template>
  <div>
    <div v-if="bundleProducts.length > 0">
      <div class="main-wrapper min-vh-100 position-relative">
        <NavHeaderSidebar />
        <section class="tw-main">
          <div class="tw-section tw-main-hero-top">
            <div class="tw-section-wrapper">
              <div class="tw-hero-text">
                <h4 class="hero-title-text font-20 mb-0">
                  {{ $t("w_back") }}
                  <span class="text-capitalize"
                    >{{ user.firstName }} {{ user.lastName }}</span
                  >
                </h4>
                <p class="hero-sub-text font-14 mt-2">
                  {{ $t("w_ot") }}
                </p>
              </div>
              <!-- search box group -->
              <!-- <Search /> -->
              <!-- end search box group -->
            </div>
          </div>
          <div class="store-section main-store">
            <div class="store-inner">
              <div
                class="d-flex justify-content-between align-items-center mb-2"
              >
                <div class="product-title d-flex align-items-center">
                  <h6 class="font-weight-bold mb-0">
                    {{ $t("b_offers") }}
                  </h6>
                  <img src="@/assets/img/4.svg" alt="" />
                </div>
                <div class="view-all">
                  <router-link to="bundle-offers"
                    >{{ $t("v_all") }}
                    <i class="fas fa-angle-double-right ml-2"></i>
                  </router-link>
                </div>
              </div>
              <ul class="store-item-list p-0">
                <li
                  class="store-list"
                  v-for="(product, index) in bundleProducts"
                  :key="index"
                >
                  <router-link
                    to="/bundle-offers"
                    class="store-item bundleProducts"
                  >
                    <div class="item-details">
                      <div class="item-img">
                        <img :src="product.productBundle.imageURL" alt="" />
                      </div>
                      <div class="item-name">
                        <h5 class="text-uppercase">
                          {{ product.productBundle.displayName }}
                        </h5>
                        <span class=""
                          >{{ user.country.currencySymbol }}
                          {{ product.productBundle.unitPrice }}</span
                        >
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>

            <div class="store-inner mt-4">
              <div
                class="d-flex justify-content-between align-items-center mb-2"
              >
                <div class="product-title d-flex align-items-center">
                  <h6 class="font-weight-bold mb-0">{{ $t("products") }}</h6>
                  <img src="@/assets/img/3.svg" class="ml-2" alt="" />
                </div>
                <div class="view-all">
                  <router-link to="products"
                    >{{ $t("v_all") }}
                    <i class="fas fa-angle-double-right ml-2"></i>
                  </router-link>
                </div>
              </div>
              <ul class="store-item-list p-0">
                <li
                  class="store-list"
                  v-for="product in products"
                  :key="product.id"
                >
                  <router-link to="/products" class="store-item">
                    <div class="item-details">
                      <div class="item-img">
                        <img :src="product.product.imageURL" alt="" />
                      </div>
                      <div class="item-name">
                        <h5 class="text-uppercase">
                          {{ product.product.productName }}
                        </h5>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <footers />
        </section>
      </div>
      <NavMobile />
    </div>
    <StoreProduct v-else />
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import NavHeaderSidebar from "../../components/dashboard/NavHeaderSidebar.vue";
  import Search from "../../components/dashboard/SearchItems.vue";
  import NavMobile from "../../components/dashboard/NavMobile.vue";
  import footers from "../../components/dashboard/footer.vue";
  import { Fragment } from "vue-fragment";
  import StoreProduct from "./products.vue";

  export default {
    name: "store",
    components: {
      NavHeaderSidebar,
      Search,
      Fragment,
      NavMobile,
      footers,
      StoreProduct,
    },
    computed: {
      ...mapState({
        cart: (context) => context.cart,
        user: (context) => context.user,
      }),
    },
    data() {
      return {
        products: [],
        units: [],
        convert: {},
        setTrue: true,
        edit: false,
        bundleProducts: [],
      };
    },
    methods: {
      getProducts() {
        this.$store
          .dispatch(
            "getProduct",
            "services/app/Products/GetAllForCustomer?MaxResultCount=4&customerId=" +
              this.user.id
          )
          .then((resp) => {
            // console.log(resp);
            if (resp.data.success) {
              this.products = resp.data.result.items;
            }
          });
      },
      async getBundleOffers() {
        await this.$store
          .dispatch(
            "get",
            "services/app/ProductBundle/GetAllForCustomer?MaxResultCount=4&customerId=" +
              this.user.id
          )
          .then((resp) => {
            this.bundleProducts = resp.data.result.items;
          });
      },
    },
    async created() {
      await this.getBundleOffers();
      this.getProducts();
    },
  };
</script>

<style scoped>
  .view-all a {
    color: black;
    font-size: 12px;
    font-weight: bold;
  }
  .product-title img {
    margin-left: 6px;
    width: 17px;
  }
  .count-value {
    width: 45px;
    text-align: center;
    border: 1px solid #e6e7ea;
    border-radius: 0;
    outline: none;
    height: 30px;
  }
  .store-list .store-item .item-details .item-img {
    width: 50px;
    min-width: 50px;
  }
  .store-list .store-item .item-details .item-img img {
    width: 100%;
    object-fit: contain;
  }
  .vue-select-group select {
    border: none;
    color: rgba(6, 20, 53, 0.7);
    background: #fff;
    margin-left: -3px;
  }
</style>
