<template>
  <div class="main-wrapper min-vh-100 position-relative">
    <HeadlessNav :page="'pay with MoMo agent'" />
    <section class="tw-main">
      <div class="section-top-stack">
        <div class="tw-main-detials">
          <form class="form-include" @submit.prevent="payNow()">
            <div class="form-group top-group-img mb-4">
              <img src="/assets/img/momo-payment-2.png" alt="" />
            </div>
            <div class="form-group">
              <label for="amount" class="form-group-label">
                Amount(<img src="/assets/img/naira-sm.svg" alt="" />)
              </label>
              <div class="form-control-group form-control-splitted">
                <span class="form-splitted currency-name">{{user.country.currencySymbol}}</span>
                <input
                  type="text"
                  class="input-control"
                  name="amount"
                  id="amount"
                  disabled
                  :value="checkout.orderTotalPrice"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="amount" class="form-group-label">
                Phone Number
              </label>
              <div class="form-control-group form-control-splitted">
                <span class="form-splitted currency-name">+{{user.country.countryCode}}</span>
                <input
                  type="text"
                  class="input-control"
                  name="amount"
                  id="amount"
                  v-model="phone"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="amount" class="form-group-label">
                Add a note <span class="text-blotted">(optional)</span>
              </label>
              <textarea
                class="input-textarea"
                name="note"
                id=""
                cols="30"
                rows="10"
                placeholder="Write a short note..."
                v-model="note"
              ></textarea>
            </div>
             <div class="form-group">
               <button type="submit" class="btn btn-secondary w-100 py-3">
                 <span style="font-size:16px" v-if="!loading">Pay now</span>
                 <span style="font-size:16px" v-else>Loading...</span>
               </button>
             </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import HeadlessNav from "../../../components/dashboard/HeaderlessNav.vue";
import { mapState } from "vuex";
export default {
  name: "payment-method",
  components: { HeadlessNav },
    computed: {
    ...mapState({
      user: (context) => context.user,
      checkout: (context) => context.checkout,
    }),
  },
  data(){
    return {
      loading:false,
      phone:this.$store.state.user.phoneNumber,
      note:''
    }
  },
  methods:{
    payNow(){
      this.loading = true
      const details ={
        // amount:this.checkout.orderTotalPrice,
        amount:"100",
        currency:'EUR',
        // externalId:this.checkout.referenceId,
        externalId:'12222222',
        payer: {
          partyIdType: "MSISDN",
          partyId: "46733123454"
          // partyId: this.phone
        },
        // payerMessage: this.note,
        payerMessage: "payer note",
        payeeNote: ""
        // currency:this.user.country.currency,

      }
      axios.post('https://sandbox.momodeveloper.mtn.com/collection/v1_0/requesttopay',details,{
        headers: {
          "content-type":'application/json',
          "Authorization": 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSMjU2In0.eyJjbGllbnRJZCI6ImFlNzJkMjhmLWVlZWQtNDNlYy1iMzNkLWFkOWM2ZTEwMTdmMiIsImV4cGlyZXMiOiIyMDIyLTAzLTI0VDE0OjM3OjMzLjY5OCIsInNlc3Npb25JZCI6IjRkNDU4ZjM5LTM4MTQtNGM5Zi1hYTIyLTFiZTc4M2ZlNjY5YiJ9.X7EEm2xpCRL16phKeIo0qyPEBrZnDirzo_0ucmtxRVhAShJX7yNBvr32w6fkuZ0xtG4ElcmzX38_7zBjc8nfqGlSN11PNqPVcszjQC_KSC8koelFlazRkn9wMsDXQPxgCCEZ9bqsPrZv2Kcrgu7zQkQUVaTM1ay4vOyu0w6Ye_hS2kxy72zRiifnGJ2nbi0iF8nvM4HnFfIhzDix5RUy6x_HgH9C5UdI4ZDCtRMjvATKivOVlt_TDkrGRlEMN7W12EP-iQhRvQIIsrVIq2aU-6s_FT9lo7DtGu_jtDEUaR_UlOP6AKwRPWO4Zd4fkwFPUpSazH9jqnhwVBmr78PNwQ',
          // "X-Reference-Id": this.checkout.referenceId,
          "X-Reference-Id": "f3ad47ce-a29f-403d-b84f-52c5ac2cd826",
          "X-Target-Environment": 'sandbox',
          "Ocp-Apim-Subscription-Key":"ffde1a02903949cc83ff39ec2e14eab8"
        }
      })
      .then(resp=>{
        this.loading = false
        console.log(resp)
      })
      .catch(error=>{
        this.loading = false
        console.log(error)
      })
    }
  },
  created(){
    console.log(this.$store.state.checkout)
  }
};
</script>

<style>
.form-include .form-group {
  margin-bottom: 1.2rem;
}
.form-include .form-group .form-group-label {
  color: rgba(6, 20, 53, 0.7);
  margin-bottom: 8px;
}
.form-control-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.form-include .form-control-group .form-splitted {
  color: rgba(6, 20, 53, 0.7);
  margin-right: 15px;
  border: 0.5px solid rgba(6, 20, 53, 0.25);
  border-radius: 5px;
  width: 65px;
  height: 45px;
  line-height: 3;
  text-align: center;
}
.form-include .form-control-group .input-control {
  border: 0.5px solid rgba(6, 20, 53, 0.25);
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  height: 45px;
  line-height: 3;
}
.form-include .form-control-group .input-control:focus {
  outline: none !important;
}
.input-textarea {
  border: 0.5px solid rgba(6, 20, 53, 0.25);
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  outline: none;
}
.input-textarea::placeholder,
.form-group-label .text-blotted {
  color: rgba(6, 20, 53, 0.3);
  font-size: 12px;
}
</style>
