import Vue from "vue";
import Router from "vue-router";
import Index from "../pages/index.vue";
import DashboardLayout from "../components/DashboardLayout";
import PublicLayout from "../components/PublicLayout";
import { dashboardRoutes } from "./dashboard";
import { publicRoutes } from "./public";
import Login from "../pages/public/login";
import Age from "../pages/public/age";
import UnderAge from "../pages/public/underAge.vue";

Vue.use(Router);

const routes = [
  {
    path: "",
    component: Index,
    children: [
      {
        path: "/",
        component: Age,
        name: "age",
      },
      {
        path: "/under-age",
        component: UnderAge,
        name: "UnderAge",
      },
      {
        path: "/login",
        component: Login,
        name: "home",
      },
      {
        path: "/",
        component: DashboardLayout,
        children: [...dashboardRoutes],
        meta: { AuthRequired: true },
      },
      {
        path: "/",
        component: PublicLayout,
        children: [...publicRoutes],
      },
    ],
  },
];

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const mobileCheck =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  if (!mobileCheck) {
    window.location.href = process.env.VUE_APP_DESKTOP_ENDPOINT + to.path;
  }
  next();
});

export default router;
