export default {
  methods: {
    formatPrice(value) {
      var hold = parseFloat(value).toFixed(2);
      return new Intl.NumberFormat("en-NG", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(hold);
    },
  },
};
